<template>
    <main class="container pt-5 pb-3">
        <slot></slot>
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useDark from '../composables/useDark.ts';

export default defineComponent({
    setup() {
        useDark();
    },
});
</script>
